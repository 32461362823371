import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import './LandingPageContent.css';

function LandingPageSuccess() {
  const [isGradientRightToLeft, setIsGradientRightToLeft] = useState(false);
  const [isImageVisible, setIsImageVisible] = useState(true);

  const handleButtonClick = () => {
    setIsGradientRightToLeft(!isGradientRightToLeft);
    setIsImageVisible(!isImageVisible);
  };

  const gradientStyle = {
    background: isGradientRightToLeft
      ? 'linear-gradient(to left, #EE8031 50%, #F5C343 50%)'
      : 'linear-gradient(to right, #EE8031 50%, #F5C343 50%)',
  };
  
  return (
      <div className="App">
        <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-J8KD2LHQP0"></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-J8KD2LHQP0');`}
        </script>
      </Helmet>
        <div className="upside">
            <div className="header">
                  <div className="logo">
                    {
                      isImageVisible ? ( <img alt='' src='%PUBLIC_URL%/../../gameland-arabic.png'/> ) : ( <img alt='' src='%PUBLIC_URL%/../../gameland-english.png'/> )
                    }
                  </div>
                  <div className="language" style={gradientStyle} onClick={handleButtonClick}>
                    <p>عر</p>
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" strokeWidth="2" d="M12,23 C18.0751322,23 23,18.0751322 23,12 C23,5.92486775 18.0751322,1 12,1 C5.92486775,1 1,5.92486775 1,12 C1,18.0751322 5.92486775,23 12,23 Z M12,23 C15,23 16,18 16,12 C16,6 15,1 12,1 C9,1 8,6 8,12 C8,18 9,23 12,23 Z M2,16 L22,16 M2,8 L22,8"></path></svg>
                    <p>en</p>
                  </div>
            </div>

            <div className="title">
              {
                isImageVisible ? ( <h1>اكتشف 100 لعبة مميزة: عالم ينتظرك مليء بالإثارة والمغامرة والتحدي</h1> ) : ( <h1>100+ Premium Games: Unleash your inner hero in worlds of adventure, challenge, and excitement</h1> )
              }
              <div className="photo">
                <img alt='' src='%PUBLIC_URL%/../../gameland.png'/> 
              </div>
            </div>
        </div>

        <div className="content" style={{textAlign: "center", fontSize: "13px", direction:"rtl"}}>
          {
            isImageVisible ? ( <h1>شكراً لاشتراكك في لعبة GameLand. يمكنك الأن الحصول على المحتوى </h1> ) : ( <h1>Thank you for subscribing to GameLand. You can now access the content</h1> )
          }
        </div>
        
        </div>
  );
}                
export default LandingPageSuccess;