import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import './LandingPageContent.css';
import { Helmet } from 'react-helmet';

function LandingPageFailed() {

  const [isGradientRightToLeft, setIsGradientRightToLeft] = useState(false);
  const [isImageVisible, setIsImageVisible] = useState(true);

  const handleButtonClick = () => {
    setIsGradientRightToLeft(!isGradientRightToLeft);
    setIsImageVisible(!isImageVisible);
  };

  const gradientStyle = {
    background: isGradientRightToLeft
      ? 'linear-gradient(to left, #EE8031 50%, #F5C343 50%)'
      : 'linear-gradient(to right, #EE8031 50%, #F5C343 50%)',
  };

  //VARIABLES
const location = useLocation();
const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
useEffect(() => {
  if(queryParams.get("reason") && queryParams.get("reason") === "nohe")
  {
    if(queryParams.get("uniqid"))
    {
      window.location.href = "https://subscribe.iq-gameland.com/lp/pin?uniqid="+queryParams.get("uniqid");
      return;
    }
    window.location.href = "https://subscribe.iq-gameland.com/lp/pin";
  }
}, [queryParams]);

  return (
      <div className="App">
        <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-4EP7NF77Q3"></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-4EP7NF77Q3');`}
        </script>
        </Helmet>

        <div className="upside">
            <div className="header">
                  <div className="logo">
                    {
                      isImageVisible ? ( <img alt='' src='%PUBLIC_URL%/../../gameland-arabic.png'/> ) : ( <img alt='' src='%PUBLIC_URL%/../../gameland-english.png'/> )
                    }
                  </div>
                  <div className="language" style={gradientStyle} onClick={handleButtonClick}>
                    <p>عر</p>
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" strokeWidth="2" d="M12,23 C18.0751322,23 23,18.0751322 23,12 C23,5.92486775 18.0751322,1 12,1 C5.92486775,1 1,5.92486775 1,12 C1,18.0751322 5.92486775,23 12,23 Z M12,23 C15,23 16,18 16,12 C16,6 15,1 12,1 C9,1 8,6 8,12 C8,18 9,23 12,23 Z M2,16 L22,16 M2,8 L22,8"></path></svg>
                    <p>en</p>
                  </div>
            </div>

            <div className="title">
              {
                isImageVisible ? ( <h1>اكتشف 100 لعبة مميزة: عالم ينتظرك مليء بالإثارة والمغامرة والتحدي</h1> ) : ( <h1>100+ Premium Games: Unleash your inner hero in worlds of adventure, challenge, and excitement</h1> )
              }
              <div className="photo">
                <img alt='' src='%PUBLIC_URL%/../../gameland.png'/> 
              </div>
            </div>
        </div>

        <div className="content" style={{textAlign: "center", fontSize: "13px", direction:"rtl"}}>
          {
            isImageVisible ? ( <h1 id="text-ar"> {queryParams.get("reason") && queryParams.get("reason").includes("time per 24 hours") ? 'عزيزي المشترك يرجى المحاولة بعد 24 ساعة' : 'عذرا لم يتم الاشتراك، للاشتراك في عالم العاب ارسل GL الى 3368'} </h1> ) 
              : ( <h1 id="text-en">{queryParams.get("reason") && queryParams.get("reason").includes("time per 24 hours") ? 'Subscriptions Exceeded Same service one time per 24 hours' : 'Subscription Failed. Send GL to 3368 to Subscribe'}</h1> )
          }
        </div>
        
        </div>
  );
}                
export default LandingPageFailed;